// const base_url = 'http://localhost/help101_backend/api';
const base_url = "https://stagging.hatinco.com/help101_backend/api";
// const base_url = "https://stagging.hatinco.com/help101_backend/api";

export const Constant = {
  googleMapApi:"AIzaSyDN7qNLtk5qz-LqPRUTjC-XyPh3ri-zKOg",
  basic_token: "Basic YmFzaWM6WW1GemFXTTZhR1ZzY0RFd01TMWhjSEF0Ylc5aWFXeGw=",
  client_id:"help101-app-mobile",
  guest_user: {
    id: "0",
    name: "Guest",
    access_token:
      "Basic YmFzaWM6WW1GemFXTTZhR1ZzY0RFd01TMWhjSEF0Ylc5aWFXeGw=",
    role_id: 2,
    rememberme: false,
    is_loggedin: false,
  },
  google_api_key:"AIzaSyAo9XGd-Ss75Cnfqqu41SdDvlwRu1WYKB0",
  login: `${base_url}/login`,
  // sendotp: `${base_url}/sendotp`,
  validate_otp: `${base_url}/validate_otp`,
  atempt_login: `${base_url}/login`,
  user_update_profile: `${base_url}/update`,
  get_language: `${base_url}/language/getlanguage`, 
  get_community: `${base_url}/community/getcommunity`, 
  get_category_level: `${base_url}/category/level`, 
  get_category_result: `${base_url}/categoryresult/get_categoryresult`, 
  add_company: `${base_url}/company/register`, 
  add_job_step1: `${base_url}/company/add_job_step1`, 
  get_jobs: `${base_url}/get_jobs`, 
  job: `${base_url}/job`, 
  delete_job: `${base_url}/delete_job`, 
  add_remove_bookmark: `${base_url}/add_remove_bookmark`, 
  delete_bookmark: `${base_url}/delete_bookmark`, 
  get_bookmarks: `${base_url}/get_bookmarks`, 
  // add_job_step2: `${base_url}/company/add_job_step2`, 
  // add_job_step3: `${base_url}/company/add_job_step3`, 
  // add_job_step4: `${base_url}/company/add_job_step4`, 
};
